import { Controller } from 'stimulus'

export default class extends Controller {

  static values = {
    url: String,
  }

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  checkboxes(fn) {
    document.querySelectorAll('input.invite-trigger:enabled').forEach(fn)
  }

  checkAllCheckboxes(event) {
    event.preventDefault()

    const { currentTarget: link } = event
    const cmd = link.textContent

    if (cmd === 'Select All') {
      this.checkboxes((checkbox) => checkbox.checked = true)
      link.textContent = 'Deselect All'
    } else {
      this.checkboxes((checkbox) => checkbox.checked = false)
      link.textContent = 'Select All'
    }
  }

  makeDeliveries(event) {
    event.preventDefault()

    const checked = [...document.querySelectorAll('input.invite-trigger:checked:enabled')]
    const invites = checked.map((checkbox) => checkbox.getAttribute('value'))

    if (invites.length > 0 && this.urlValue) {
      fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.token,
        },
        body: JSON.stringify({invites}),
      }).then(() => {
        checked.forEach((checkbox) => checkbox.disabled = true)
      }).finally(() => {
        setTimeout(() => {
          checked.forEach((checkbox) => {
            const indicator = document.createElement('span')
            indicator.classList.add('text-small')
            indicator.textContent = 'Invitation Sent' 
            checkbox.replaceWith(indicator)
          })
        }, 450)
      })
    }
  }
}
