import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = ['overflow']

  // TODO: pull this trigger out of the generic modal class
  connect() {
    let queryString = window.location.search
    let urlParams = new URLSearchParams(queryString)

    urlParams.get('sign_in') == 'welcome' ? this.showStrengthModal() : ''

    // add an event listener toggle externally (toggle)
  }

  showStrengthModal() {
    let modal = document.getElementById('modal-strength_of_relationship')

    if (modal) {
      modal.classList.remove('hide')

      this.positionOverflowGradient(modal)

      let queryString = window.location.search
      let urlParams = new URLSearchParams(queryString)
      urlParams.delete('sign_in')
      history.pushState('/dashboard?sign_in=welcome', null, "/dashboard");
      history.pushState(null, null, "?" + urlParams.toString());
    }
  }

  toggle() {
    event.preventDefault()

    let target_modal_id = event.currentTarget.classList;
    if (target_modal_id.length > 1) {
      target_modal_id = target_modal_id[0]
    }

    let modal = this.element.querySelector('#modal-' +  target_modal_id)
    if (!modal) {
      modal = document.querySelector('#modal-' +  target_modal_id)
    }

    modal.classList.toggle('hide')

    // Set position of gradient overlay
    // to indicate content may be
    // available on scroll
    // Disable this for now
    this.positionOverflowGradient(modal)

    event.stopPropagation()
  }

  close() {
    event.preventDefault()
    event.target.closest('.modal').classList.add('hide')
    event.stopPropagation()
  }

  positionOverflowGradient(modal) {
    // alert('position')
    // to do: use stimulus target instead
    const modalGradient = modal.querySelector('.modal-overflow-gradient')
    const modalButtons = modal.querySelector('.modal-buttons')

    // Check if buttons exist in the modal
    // set the appropriate position
    if (!!modalGradient && modalButtons && modalButtons.offsetParent != null){
      const height = modalButtons.offsetHeight
      modalGradient.style.bottom = height + 'px'
    }
  }
}
