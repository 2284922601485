import { Controller } from "stimulus";
import Rails from "@rails/ujs";

import tippy from 'tippy.js'

export default class extends Controller {
  static targets = [
    'defaultSort', 'backupSort', 'personDropdown', 'paginationCompanies', 'paginationPeople', 'companyList',
    'personList', 'tabs', 'relationshipPercentage', 'peopleGuideTextCta', 'peopleAddRelationshipsCta',
    'pageHeading', 'personHide', 'companyHide', 'tabsNav'
  ]
  loading = false;

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    if (window.location.href.indexOf("dashboard") > -1) {
      this.switchTabs()
    }
    this.loadCompaniesData()
  }

  loadCompaniesData() {
    this.hideLoader()
    let companySort
    const relevanceFlag = this.defaultSortTarget.dataset.relevanceFlag === 'true'
    if (relevanceFlag) {
      this.defaultSortTarget.classList.add('active')
      companySort = this.defaultSortTarget.getAttribute('data-sort-value')
    } else {
      this.backupSortTarget.classList.add('active')
      companySort = this.backupSortTarget.getAttribute('data-sort-value')
    }
    Rails.ajax({
      type: 'GET',
      url: '/dashboard/companies',
      dataType: 'json',
      data: new URLSearchParams({ company_sort: companySort }).toString(),
      success: (data) => {
        this.fillCompaniesData(data)
      }
    })
  }

  sortCompany(event) {
    let filterPills = this.element.querySelectorAll('.sort-item')
    filterPills.forEach(item => item.classList.remove('active'))
    event.target.classList.add('active')
    let activeTabCompany = this.tabsTarget.querySelector('#nav-companies').classList.contains('active')

    if (activeTabCompany) {
      document.querySelector('.company-loader').parentNode.classList.remove('hide')
      this.companyListTarget.innerHTML = ""
    } else {
      document.querySelector('.person-loader').parentNode.classList.remove('hide')
      this.personListTarget.innerHTML = ""
    }

    let url = ''
    let sortBy = event.currentTarget.getAttribute('data-sort-value');
    
    url = activeTabCompany ? '/dashboard/companies' : '/dashboard/people';
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      data: new URLSearchParams({ company_sort:sortBy }).toString(),
      success: (data) => {
        if (activeTabCompany) {
          this.fillCompaniesData(data);
        } else {
          this.fillPeopleData(data);
        }
        this.hideLoader()
      }
    })
  }

  switchTabs() {
    if (this.tabsNavTarget.querySelector('#nav-people-tab').classList.contains('active')) {
      this.loadPeopleData()
      this.pageHeadingTarget.innerHTML = 'people'
    } else {
      this.loadCompaniesData()
      this.pageHeadingTarget.innerHTML = 'companies'
    }
  }

  loadPeopleData() {
    this.hideLoader()
    Rails.ajax({
      type: 'GET',
      url: '/dashboard/people',
      dataType: 'json',
      data: new URLSearchParams({ company_sort: this.personDropdownTarget.value }).toString(),
      success: (data) => {
        this.fillPeopleData(data)
      }
    })
  }

  // Hide show companies
  companyHide(event) {
    const payload = JSON.parse(event.target.dataset.value)
    if (event.target.classList.contains('hidden-card')) {
      this.companyUnhide(event)
    } else {
      Rails.ajax({
        type: 'POST',
        url: '/hidden_companies/',
        dataType: 'json',
        data: new URLSearchParams({ company_id: payload.companyId }).toString(),
        success: (data) => {
          let row = event.target.closest('.target-company-card')
          this.addClassHiddenCard(row, event)
          this.hideShowComponent([row], 'show_hidden_companies')
          this.generateToastr(`${payload.companyName} is now hidden from your list.`)
        }
      })
    }
  }

  companyUnhide(event) {
    const payload = JSON.parse(event.target.dataset.value)
    Rails.ajax({
      type: 'DELETE',
      url: '/hidden_companies/',
      dataType: 'json',
      data: new URLSearchParams({ company_id: payload.companyId }).toString(),
      success: (data) => {
        let row = event.target.parentNode.parentNode.closest('.target-company-card')
        this.removeClassHiddenCard(row, event)
        this.generateToastr(`${payload.companyName} is now visible in your list.`)
      }
    })
  }

  // Hide show people
  personHide(event) {
    const payload = JSON.parse(event.target.dataset.value)
    if (event.target.classList.contains('hidden-card')) {
      this.personUnhide(event)
    } else {
      Rails.ajax({
        type: 'POST',
        url: '/v2/dashboard/hide/',
        dataType: 'json',
        data: new URLSearchParams({
          prospect_type: 'person',
          prospect_id: payload.personId
        }).toString(),
        success: (data) => {
          let row = event.target.closest('.target-profile-card')
          this.addClassHiddenCard(row, event)
          this.hideShowComponent([row], 'show_hidden_people')
          this.generateToastr(`${payload.personFirstName} ${payload.personLastName} is now hidden from your list.`)
        }
      })
    }
  }

  personUnhide(event) {
    const payload = JSON.parse(event.target.dataset.value)
    Rails.ajax({
      type: 'POST',
      url: '/v2/dashboard/unhide/',
      dataType: 'json',
      data: new URLSearchParams({ prospect_type: 'person', prospect_id: payload.personId }).toString(),
      success: (data) => {
        let row = event.target.closest('.target-profile-card')
        this.removeClassHiddenCard(row, event)
        this.generateToastr(`${payload.personFirstName} ${payload.personLastName} is now visible in your list.`)
      }
    })
  }

  hideShowCompanies (event) {
    let companyBoxes = this.companyListTarget.querySelectorAll('.target-company-card')
    this.hideShowComponent(companyBoxes, 'show_hidden_companies')
  }
  hideShowPeople() {
    let personBoxes = this.personListTarget.querySelectorAll('.target-profile-card')
    this.hideShowComponent(personBoxes, 'show_hidden_people')
  }

  fetchTooltip(event) {
    const id = event.target.dataset.companyId
    event.stopImmediatePropagation()
    if (id && event.target.parentNode.querySelector('.tooltip-text .data-loader')) {
      Rails.ajax({
        type: 'get',
        url: '/dashboard/tooltip/',
        dataType: 'json',
        data: new URLSearchParams({ company_id: id }).toString(),
        success: (data) => {
          event.target.parentNode.querySelector('.tooltip-text').innerHTML = data.tooltip
        }
      })
    }
  }

  hideLoader() {
    let personPagination = document.getElementById("nav-people")//.querySelector('.pagination');
    if (personPagination.querySelector(".pagination .page.next.disabled")) {
      document.querySelector('.person-loader').parentNode.classList.add('hide')
    }
    let companyPagination = document.getElementById("nav-companies")//.querySelector('.pagination');
    if (companyPagination.querySelector(".pagination .page.next.disabled")) {
      document.querySelector('.company-loader').parentNode.classList.add('hide')
    }
  }

  openOfferHelpModal(event) {
    event.preventDefault()
    let relationshipId = event.target.dataset.relationshipId
    var target_modal_id = event.currentTarget.classList;
    if (target_modal_id.length > 1) {
      target_modal_id = target_modal_id[0]
    }

    Rails.ajax({
      type: 'get',
      url: '/dashboard/offer_help_modal',
      dataType: 'script',
      data: new URLSearchParams({
        relationship_id: relationshipId,
        modal: target_modal_id
      }).toString(),
      success: (data) => {}
    })
  }


  openOfferHelpModalCompany(event) {
    event.preventDefault()
    let companyId = event.target.dataset.companyId
    let companyName = event.target.dataset.companyName
    var target_modal_id = event.currentTarget.classList;
    if (target_modal_id.length > 1) {
      target_modal_id = target_modal_id[0]
    }

    Rails.ajax({
      type: 'get',
      url: '/dashboard/offer_help_company',
      dataType: 'script',
      data: new URLSearchParams({
        company_id: companyId,
        company_name: companyName,
        modal: target_modal_id
      }).toString(),
      success: (data) => {}
    })
  }

  hideShowComponent(elements, className) {
    elements.forEach(row => {
      if (document.getElementById(className).checked) {
        if (row.classList.contains('hidden-card')) {
          row.classList.remove('hide')
        }
      } else {
        if (row.classList.contains('hidden-card')) {
          row.classList.add('hide')
        }
      }
    })
  }

  pagyCompaniesNav(event) {
    event.preventDefault()
    Rails.ajax({
      type: 'get',
      url: event.target.href,
      dataType: 'json',
      success: (data) => {
        this.fillCompaniesData(data)
        var companyList = this.companyListTarget.querySelectorAll('.target-company-card')
        this.hideShowComponent(companyList, 'show_hidden_companies')
        this.scrollToTop()
      }
    })
  }

  pagyPeopleNav(event) {
    event.preventDefault()
    Rails.ajax({
      type: 'get',
      url: event.target.href,
      dataType: 'json',
      success: (data) => {
        this.fillPeopleData(data)
        var personList = document.querySelectorAll('.target-profile-card')
        this.hideShowComponent(personList, 'show_hidden_people')
        this.scrollToTop()
      }
    })
  }

  fillCompaniesData(data) {
    this.companyListTarget.innerHTML = data.targeted_companies
    this.paginationCompaniesTarget.innerHTML = data.pagination
    document.querySelector('.company-loader').parentNode.classList.add('hide')
    this.initializeTippy()
  }

  fillPeopleData(data) {
    this.personListTarget.innerHTML = data.people
    this.paginationPeopleTarget.innerHTML = data.pagination
    this.togglePeopleCta(data.relationshipCount)
    document.querySelector('.person-loader').parentNode.classList.add('hide')
    this.initializeTippy()
  }

  togglePeopleCta(relationshipCount) {
    if (relationshipCount > 0) {
      this.peopleGuideTextCtaTarget.classList.remove('hide')
      this.peopleAddRelationshipsCtaTarget.classList.add('hide')
    } else {
      this.peopleGuideTextCtaTarget.classList.add('hide')
      this.peopleAddRelationshipsCtaTarget.classList.remove('hide')
    }
  }

  addClassHiddenCard(row, event) {
    if (row) row.classList.add('hidden-card')
    let parentNodeClasses = event.target.parentNode.classList
    if (event.target) {
      parentNodeClasses.add('hidden-card')
      event.target.classList.add('hidden-card')
    }
  }

  removeClassHiddenCard(row, event) {
    if (row) row.classList.remove('hidden-card')
    if (event.target) {
      event.target.parentNode.classList.remove('hidden-card')
      event.target.classList.remove('hidden-card')
    }
  }

  generateToastr(message) {
    toastr.remove()
    toastr.success(message)
  }

  initializeTippy() {
    tippy('[data-tippy-content]', {
      allowHTML: true,
      arrow: true,
      maxWidth: 400,
      inertia: true,
    })
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}
