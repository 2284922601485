import { Controller } from "stimulus"

const MIN_CHAR_LENGTH = 2

// TODO: we shouldn't have bespoke modal classes!
export default class extends Controller {
  static targets = ['category', 'charcount', 'required', 'highpriority']

  connect() {
    this.priorityDesctriptionEl = document.getElementById('prioritydescription')
    this.charCountEl = document.getElementById('charcount')
    this.requiredTarget.style.display = "none"
    this.checkForm()
  }

  toggle(event) {
    const isChecked = event.target.checked
    this.priorityDesctriptionEl.required = isChecked
    if (isChecked) {
      this.requiredTarget.style.display = "block"
    } else {
      this.requiredTarget.style.display = "none"
      this.priorityDesctriptionEl.value = ""
    }
  }

  count() {
    const maxLength = 300
    const currentLength = this.priorityDesctriptionEl.value.length
    const remaining = maxLength - currentLength
    this.charCountEl.textContent = `${remaining} characters remaining`
  }

  checkForm() {
    const inputGroup = document.getElementById('add-company-input-group')
    const input = inputGroup.querySelector('#company-autosuggest') || ''
    const inputLength = input?.value?.trim().length || 0
    const submitButton = inputGroup.querySelector('input[type="submit"][value="Add"]')
    submitButton.disabled = inputLength < MIN_CHAR_LENGTH
  }

  uploadFile(event) {
    if (event.target.files && event.target.files[0]) {
      var skillsSelect = document.getElementById("target_category");
      var selectedText = skillsSelect.options[this.categoryTarget.selectedIndex].text;
      document.querySelector('.category').innerHTML = selectedText
      document.querySelector('.filename').innerHTML = event.target.files[0].name
      document.querySelector('.file-upload-preview').classList.remove('hide')
      document.querySelector('.file-upload-main').classList.add('hide')
    }
  }

  handleCompanyAutosuggestInputChange() {
    this.checkForm()
  }

  validateForm(event) {
    const highPriorityChecked = document.querySelector('input[name="high_priority"]').checked
    const priorityDescriptionValue = this.priorityDesctriptionEl.value.trim()

    if (highPriorityChecked && !priorityDescriptionValue) {
      this.priorityDesctriptionEl.classList.add('form-error')
      event.preventDefault()
    }
  }
}
